import SocialIcons from "./SocialIcons";

function Footer() {
	return (
		<>
			<footer className="footer">
				<div className="footer__container">
					<p>© 2022 Studio Morte</p>
					<SocialIcons />
				</div>
			</footer>
		</>
	);
}

export default Footer;
